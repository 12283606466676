<template>
  <main-layout>
    <v-container fluid>
      <v-row>
        <v-col
          xs="12"
          lg="10"
        >
          <h2 class="headline mb-3">Noodnummers</h2>
          <base-divider
            color="accent"
            dense
          />
          <v-data-table
            :headers="headers"
            :items="desserts"
            hide-default-header
            hide-default-footer
          >
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </main-layout>

</template>

<script>
  import MainLayout from '@/layouts/Main.vue'
  import BaseDivider from '@/components/Divider.vue'

  export default {
    components: {
      MainLayout,
      BaseDivider
    },
    head () {
      return {title: this.$t('emergency_numbers')}
    },
    data () {
      return {
        headers: [
          { text: 'Wie?', value: 'who' },
          { text: 'Nummer', value: 'number' },
        ],
        desserts: [
          {
            who: 'Medisch spoedgeval en brandweer',
            number: '100',
          },
          {
            number: '101',
            who: 'Politie'
          },
          {
            number: '105',
            who: 'Rode Kruis Vlaanderen'
          },
          {
            number: '106',
            who: 'Tele-onthaal'
          },
          {
            number: '110',
            who: 'Child focus'
          },
          {
            number: '02/268.62.00',
            who: 'Brandwondencentrum'
          },
          {
            number: '070/245.245',
            who: 'Antigifcentrum'
          },
          {
            number: '078/15.15.15',
            who: 'Aidstelefoon'
          },
          {
            number: '078/15.10.20',
            who: 'Drugslijn'
          },
          {
            number: '0800/15.111',
            who: 'Kinder - en jongeren telefoon'
          },
          {
            number: '078/150.151',
            who: 'Vlaamse kanker telefoon'
          },
        ],
      }
    },
  }
</script>
